<template>
  <div>
    <b-card :title="isFetching ? '' : $t('addOnsTitle')">
      <div
        v-if="isFetching"
        class="d-flex justify-content-center"
      >
        <b-spinner variant="primary" />
      </div>
      <template v-else>
        <div
          v-for="serviceGroup in services"
          :key="serviceGroup.id"
          class="mt-2"
        >
          <div class="font-medium-2 mb-1">
            {{ serviceGroup.title }}
          </div>
          <b-table
            :fields="fields"
            :items="serviceGroup.items"
            :bordered="true"
            :fixed="true"
            class="table_add-ons"
          >
            <template #head(service_name)>
              {{ $t('addOnsServiceName') }}
            </template>
            <template #head(monthly_cost)>
              {{ $t('addOnsMonthlyCost') }}
            </template>
            <template #head(subscribed)>
              {{ $t('addOnsSubscribed') }}
            </template>

            <template #cell(service_name)="data">
              {{ data.item.service_name }}
              <div class="mt-25">
                <b-link v-b-tooltip.hover.bottom="data.item.service_description">
                  {{ $t('addOnsMoreInfo') }}
                </b-link>
              </div>
            </template>
            <template #cell(subscribed)="data">
              <b-spinner
                v-if="deletingIds.find(i => i === data.item.id)"
                class="d-flex spinner-20"
                variant="primary"
              />
              <b-form-checkbox
                v-else
                v-b-tooltip.hover.bottom="getCheckboxTooltip(data.item)"
                switch
                inline
                :disabled="getCheckboxIsDisabled(data.item)"
                :checked="getIsChecked(data.item)"
                @click.native.prevent="onClickCheckboxSubscribe(data.item)"
              />
            </template>
          </b-table>
        </div>
      </template>
    </b-card>
    <b-modal
      v-if="confirmData"
      v-model="isModalConfirm"
      hide-header
      hide-footer
      centered
      @hidden="onHiddenModal"
    >
      <div class="pt-1 pb-1">
        <h3 class="mb-0 text-center">
          <i18n path="addOnsModalTitle">
            <template v-slot:slot>
              <b>{{ confirmData.service_name }}</b>
            </template>
          </i18n>
        </h3>
        <p class="mt-2 mb-2">
          <i18n path="addOnsModalText">
            <template v-slot:slot>
              <b>{{ $t('addOnsConfirm') }}</b>
            </template>
            <template v-slot:slot2>
              <b>{{ confirmData.service_name }}</b>
            </template>
            <template v-slot:slot3>
              <b>{{ restaurant.name }}</b>
            </template>
            <template v-slot:slot4>
              <b>{{ confirmData.monthly_cost }}</b>
            </template>
          </i18n>
        </p>
        <b-form-checkbox
          v-model="isChargeAccepted"
          inline
          :disabled="isConfirming"
          :state="stateChargeAccepted"
          @click.native="stateChargeAccepted = null"
        >
          {{ $t('addOnsCheckboxCharge') }}
        </b-form-checkbox>
        <br><br>
        <b-form-checkbox
          v-model="isAgreeAccepted"
          inline
          :disabled="isConfirming"
          :state="stateAgreeAccepted"
          @click.native="stateAgreeAccepted = null"
        >
          <i18n path="addOnsCheckboxAgree">
            <template v-slot:slot>
              <a
                :href="`https://sporkinc.com/${locale}/terms-of-service`"
                target="_blank"
              >{{ $t('addOnsAgreeLinkNameTerms') }}</a>
            </template>
            <template v-slot:slot2>
              <a
                :href="`https://sporkinc.com/${locale}/privacy-policy`"
                target="_blank"
              >{{ $t('addOnsAgreeLinkNamePolicy') }}</a>
            </template>
          </i18n>
        </b-form-checkbox>
        <div class="d-flex align-items-center justify-content-center mt-2 pt-1">
          <b-button
            block
            variant="outline-primary"
            :disabled="isConfirming"
            @click="isModalConfirm = false"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            block
            variant="primary"
            class="d-flex justify-content-center ml-1 mt-0"
            :disabled="isConfirming"
            @click="onClickConfirm"
          >
            <b-spinner
              v-if="isConfirming"
              class="d-flex"
              small
            />
            <template v-else>
              {{ $t('addOnsConfirm') }}
            </template>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BLink,
  BFormCheckbox,
  BModal,
  BButton,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import mixinFormatter from '@/mixins/formatter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ADD_ADDON_TO_SUBSCRIPTION from '@/gql/mutation/billing/addAddonToSubscription.gql'
import REMOVE_ADDON_FROM_SUBSCRIPTION from '@/gql/mutation/billing/removeAddonFromSubscription.gql'

export default {
  name: 'AddOns',
  components: {
    BCard,
    BTable,
    BLink,
    BFormCheckbox,
    BModal,
    BButton,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    mixinFormatter,
  ],
  data() {
    return {
      isFetching: true,
      deletingIds: [],

      fields: ['service_name', 'monthly_cost', 'subscribed'],
      services: [],

      confirmData: null,
      isModalConfirm: false,
      isConfirming: false,

      isChargeAccepted: false,
      isAgreeAccepted: false,

      stateChargeAccepted: null,
      stateAgreeAccepted: null,
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      locale: 'main/locale',
      subscription: 'billing/subscription',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
      billingConfig: 'billing/billingConfig',
      isUserSporkMember: 'user/isUserSporkMember',
    }),
  },
  watch: {
    locale() {
      this.setServices()
    },
    subscription() {
      this.setData()
    },
    billingConfig() {
      this.setData()
    },
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions({
      getSubscription: 'billing/getSubscription',
      getInvoices: 'billing/getInvoices',
      getRestaurants: 'restaurant/getRestaurants',
    }),
    getCheckboxTooltip(item) {
      if (this.isSubscriptionInactive) {
        return this.$t('addOnsTooltipUserNotSubscribed')
      }
      if (this.getCheckboxIsDisabled(item)) {
        return this.$t('addOndTooltipCheckboxDisabledCanceled')
      }
      return null
    },
    getCheckboxIsDisabled(item) {
      return !!(
        this.subscription
          && this.subscription.addons.length
          && this.subscription.addons.find(i => i.id === item.id)
          && this.subscription.addons.find(i => i.id === item.id).enabled
          && this.subscription.addons.find(i => i.id === item.id).cancelAtPeriodEnd
      )
    },
    getIsChecked(item) {
      return !!(
        this.subscription
          && this.subscription.addons.length
          && this.subscription.addons.find(i => i.id === item.id)
          && this.subscription.addons.find(i => i.id === item.id).enabled
      )
    },
    getMonthlyCostValue(addonData) {
      if (addonData && addonData.currencySymbol && addonData.monthlyPay) {
        return `${addonData.currencySymbol}${this.getFormattedPrice(addonData.monthlyPay)}`
      }
      return '-'
    },
    setServices() {
      const events = this.billingConfig.addons.find(i => i.name === 'Events')
      const orders = this.billingConfig.addons.find(i => i.name === 'Orders')
      const payments = this.billingConfig.addons.find(i => i.name === 'Payments')
      // const pos = this.billingConfig.addons.find(i => i.name === 'POS')
      const googleFoodMenus = this.billingConfig.addons.find(i => i.name === 'Google Food Menus')

      this.services = [
        {
          id: 'addons',
          title: this.$t('addOns'),
          items: [
            {
              ...events,
              service_name: this.$t('addOnsEventsName'),
              service_description: this.$t('addOnsEventsDescription'),
              monthly_cost: this.getMonthlyCostValue(events),
            },
            {
              ...orders,
              service_name: this.$t('addOnsOrdersName'),
              service_description: this.$t('addOnsOrdersDescription'),
              monthly_cost: this.getMonthlyCostValue(orders),
            },
            // {
            //   ...payments,
            //   service_name: this.$t('addOnsPaymentsName'),
            //   service_description: this.$t('addOnsPaymentsDescription'),
            //   monthly_cost: this.getMonthlyCostValue(payments),
            // },
          ],
        },
        {
          id: 'integrations',
          title: this.$t('Integrations'),
          items: [
            // {
            //   ...pos,
            //   service_name: this.$t('addOnsPosName'),
            //   service_description: this.$t('addOnsPosDescription'),
            //   monthly_cost: `${pos.currencySymbol}${this.getFormattedPrice(pos.monthlyPay)}`,
            // },
            {
              ...googleFoodMenus,
              service_name: this.$t('addOnsGoogleFoodMenusName'),
              service_description: this.$t('addOnsGoogleFoodMenusDescription'),
              monthly_cost: this.getMonthlyCostValue(googleFoodMenus),
            },
            // {
            //   id: 555,
            //   service_name: this.$t('3rd party integration'),
            //   service_description: this.$t('3rd party integration description'),
            //   monthly_cost: '$99.00',
            // },
          ],
        },
      ]

      if (payments) {
        this.services = this.services.map((i, index) => {
          if (index === 0) {
            return {
              ...i,
              items: [
                ...i.items,
                {
                  ...payments,
                  service_name: this.$t('addOnsPaymentsName'),
                  service_description: this.$t('addOnsPaymentsDescription'),
                  monthly_cost: this.getMonthlyCostValue(payments),
                },
              ],
            }
          }
          return i
        })
      }
      if (!this.isUserSporkMember) {
        this.services = this.services.map(i => ({
          ...i,
          items: i.items.filter(j => j.name !== 'Payments'),
        }))
      }
    },
    async setData() {
      if (!this.subscription || !this.billingConfig) {
        return
      }

      this.setServices()

      this.isFetching = false
    },
    async onClickCheckboxSubscribe(item) {
      if (
        !item.id
        || this.isConfirming
        || this.deletingIds.length
        || this.isSubscriptionInactive
        || this.getCheckboxIsDisabled(item)
      ) {
        return
      }

      if (this.subscription.addons.find(i => i.id === item.id)) {
        await this.deleteAddon(item)
      } else {
        this.confirmData = item
        this.isModalConfirm = true
      }
    },
    async deleteAddon(item) {
      this.deletingIds = [...this.deletingIds, item.id]

      try {
        await this.$apollo.mutate({
          mutation: REMOVE_ADDON_FROM_SUBSCRIPTION,
          variables: {
            organizationId: this.restaurant.organization.id,
            addonId: item.id,
          },
        })

        await Promise.all([
          this.getSubscription(),
          this.getInvoices(),
          this.getRestaurants(),
        ])

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: error.graphQLErrors[0].id,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.deletingIds = this.deletingIds.filter(i => i !== item.id)
      }
    },
    async onClickConfirm() {
      if (!this.isChargeAccepted) {
        this.stateChargeAccepted = false
      }
      if (!this.isAgreeAccepted) {
        this.stateAgreeAccepted = false
      }
      if (!this.isChargeAccepted || !this.isAgreeAccepted) {
        return
      }

      this.isConfirming = true

      try {
        await this.$apollo.mutate({
          mutation: ADD_ADDON_TO_SUBSCRIPTION,
          variables: {
            organizationId: this.restaurant.organization.id,
            addonId: this.confirmData.id,
          },
        })

        await Promise.all([
          this.getSubscription(),
          this.getInvoices(),
        ])

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })

        this.isModalConfirm = false
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: error.graphQLErrors[0].id,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isConfirming = false
      }
    },
    onHiddenModal() {
      this.isChargeAccepted = false
      this.stateChargeAccepted = null

      this.isAgreeAccepted = false
      this.stateAgreeAccepted = null
    },
  },
}
</script>

<style lang="sass">
  .table_add-ons
    thead
      th
        text-transform: none
</style>
